import React from "react";
import theme from "theme";
import { Theme, Text, Em, Icon, Strong, Box, List, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckCircle } from "react-icons/fa";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				StarGaze Events
			</title>
			<meta name={"description"} content={"Creare ricordi indimenticabili"} />
			<meta property={"og:title"} content={"StarGaze Events"} />
			<meta property={"og:description"} content={"Creare ricordi indimenticabili"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text" />
		</Components.Header2>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Pianificazione completa di eventi
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					<Em>
						Trasformare i sogni in realtà
					</Em>
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							color="--dark"
							font="--lead"
							border-color="--color-darkL1"
						>
							<Strong>
								Decorazioni e stili personalizzati
							</Strong>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--dark" font="--lead">
							<Strong>
								Intrattenimento e attività
							</Strong>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--lead" color="--dark">
							<Strong>
								Servizi di catering
							</Strong>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--lead" color="--dark">
							<Strong>
								Logistica e Coordinamento
							</Strong>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08:22:40.315Z"
					display="block"
					border-radius="32px"
					srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-4.jpg?v=2024-06-10T08%3A22%3A40.315Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				order="1"
				align-self="center"
			>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Decorazioni e stili personalizzati
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					I nostri servizi di arredamento e styling personalizzati assicurano che il tuo evento rifletta il tuo stile e la tua personalità unici. Dalle eleganti composizioni floreali ai suggestivi centrotavola, creiamo un ambiente visivamente sbalorditivo che lascia un ricordo indelebile sui tuoi ospiti.
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							color="--dark"
							font="--base"
							border-color="--color-darkL1"
						>
							Design floreale
Bouquet e composizioni: Bouquet e composizioni floreali splendidamente realizzati che aggiungono eleganza a qualsiasi evento.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--dark" font="--base">
							Installazioni floreali: Splendide installazioni floreali che fungono da sfondo perfetto per il tuo evento.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Centritavola: Centrotavola unici ed eleganti che migliorano l'arredamento generale.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Stile dell'evento
Concettualizzazione del tema: Temi creativi che rendono il tuo evento memorabile.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Elementi decorativi personalizzati: Elementi decorativi su misura che si adattano alla tua visione.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Progettazione dell'illuminazione: Illuminazione professionale che crea l'atmosfera perfetta.
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08:22:40.334Z"
					display="block"
					border-radius="32px"
					srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Intrattenimento e attività
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Offriamo una vasta gamma di opzioni di intrattenimento per mantenere i vostri ospiti impegnati e intrattenuti durante l'evento. Dalla musica dal vivo alle attività interattive, ci assicuriamo che il tuo evento sia pieno di divertimento ed eccitazione.
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							color="--dark"
							font="--base"
							border-color="--color-darkL1"
						>
							Esibizioni dal vivo
Band e DJ: Band e DJ di talento che creano l'atmosfera giusta con la loro musica.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--dark" font="--base">
							Spettacoli di danza: Ballerini professionisti che aggiungono un tocco di eleganza ed energia.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Maghi e intrattenitori: intrattenitori che affascinano i tuoi ospiti con le loro abilità.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Attività interattive
Cabine fotografiche: Cabine fotografiche divertenti e creative che offrono ricordi memorabili.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Giochi e concorsi: Giochi e concorsi interattivi che coinvolgono i tuoi ospiti.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCheckCircle}
							size="20px"
							color="#4f46e5"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="--base" color="--dark">
							Workshop e lezioni: Laboratori educativi e divertenti che offrono esperienze uniche.
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08:22:40.314Z"
					display="block"
					border-radius="32px"
					srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-3.jpg?v=2024-06-10T08%3A22%3A40.314Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				order="1"
				align-self="center"
			>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Servizi di catering
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					I nostri servizi di catering offrono cibi e bevande deliziosi e ben presentati che delizieranno i vostri ospiti. Collaboriamo con i migliori ristoratori per offrire una varietà di opzioni di menu che soddisfino tutti i gusti e le esigenze dietetiche.
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Logistica e Coordinamento
				</Text>
				<Text
					as="h1"
					margin="1rem 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Gestiamo tutti gli aspetti logistici del tuo evento per garantire un'esperienza senza interruzioni dall'inizio alla fine. I nostri servizi di coordinamento coprono tutto, dalla gestione dei fornitori alla supervisione in loco, così puoi concentrarti sul goderti il ​​tuo evento.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08:22:40.311Z"
					display="block"
					border-radius="32px"
					srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-1.jpg?v=2024-06-10T08%3A22%3A40.311Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text4">
				Пн - Пт (10:00 - 21:00)
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});